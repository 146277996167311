<template >
    <div class="grantProject">
        <el-card>
          <div class="checkAll"><el-checkbox v-model="checkedAll" @change="toggleAll"></el-checkbox> 全选</div>
            <el-table
            ref="multipleTable"
                :data="tableData"
                row-key="lab_id"
                :tree-props="{children: 'explist', hasChildren: 'hasChildren'}">
                <el-table-column
                width="70">
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.expliststatus" @change="toggleChecked(scope.row)"></el-checkbox>
                  </template>
                    </el-table-column>
                <el-table-column
                prop="lab_id"
                label="分类id">
                </el-table-column>
                <el-table-column
                prop="lab_name"
                label="分类名称">
                </el-table-column>
            </el-table>
            <!-- <el-pagination
                @current-change="getData"
                :current-page.sync="currentPage"
                background
                :page-size="pageSize"
                layout="prev, pager, next"
                :total="explabListTotal">
            </el-pagination> -->
        </el-card>
        <div class="save">
        <!-- <el-button type="primary" @click="Back">返回</el-button> -->
        <el-button class="themeButton" @click="save">保存</el-button>
      </div>
    </div>
  
  </template>
  
  <script>
    // 引入试题列表js
    import grantProject from "@/assets/js/manager/user/grantProject.js";
    export default {
     ...grantProject
    }
  </script>
  <style>
  .grantProject .el-table .el-table__cell {
    padding: .55vw 0 !important; 
  }
  .grantProject .el-icon-check,.grantProject .el-icon-close{
    font-size: 1vw;
    font-weight: 900;
  text-align: center;
    color: #0D5570;
  }
  .grantProject .el-dialog {
    border-radius: 1vw !important;
  } 
  .grantProject .el-dialog__body {
    text-align: center;
  } 
  .grantProject .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0D5570;
    color: #FFF;
  }
  .grantProject .el-image{
    cursor: pointer;
    width: 2vw !important;
    height: 2vw !important;
  }
  .grantProject .el-table__body-wrapper {
    position: relative;
    height: 74.5vh;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: scroll;
  }
  .grantProject .el-checkbox__input.is-checked .el-checkbox__inner,.grantProject .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #0D5570;
    border-color: #0D5570;
}
  .grantProject .el-table__body-wrapper::-webkit-scrollbar{
    display: none;
  }
  </style>
  <style lang="scss" scoped>
  // 引入试题列表组件css
    @import "@/assets/css/manager/user/grantProject.scss";
  </style>