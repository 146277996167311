import { request } from "@/request/http";

/**
 * @name: 虚拟实验分类管理接口
 * @author: LXY
 * @date: 2022-10-20
 */
export default {
  // 获取授权虚拟实验列表
  getGrantExpList: (data) => {
    return request("POST", `/index.php/adminUser/getGrantExpList`, data);
  },
  updateUserExp: (data) => {
    return request("POST", `/index.php/adminUser/updateUserExp`, data);
  },
};